// export const baseurl = "http://172.16.1.176:1929/"; //local

export const baseurl = "https://node.kaekapak.com"; // Live

let admin = `${baseurl}`;

const Apiconfigs = {
  // admin Login
  login: `${admin}/adminLogin`,
  forgotPassword: `${admin}/forgotPassword`,
  addCompanyDetail: `${admin}/deleteCompany`,
  forgotPassword: `${admin}/forgotPassword`,
  dashboard: `${admin}/dashboard`,
  verifyOTP: `${admin}/verifyOTPAdmin`,
  resendOTPAdmin: `${admin}/resendOTPAdmin`,
  resetPassword: `${admin}/resetPassword`,

  //profile
  getAdminProfile: `${admin}/getAdminProfile`,
  updateAdminDetails: `${admin}/updateAdminDetails`,
  changePassword: `${admin}/changePassword`,

  //users
  getallusers: `${admin}/getallusers`,
  activeBlockUserByAdmin: `${admin}/activeBlockUserByAdmin`,
  uploadFile: `${admin}/uploadFile`,
  signup: `${admin}/signup`,

  //company
  getCompanyDetails: `${admin}/getCompanyDetails`,
  addCompanyDetails: `${admin}/addCompanyDetails`,
  deleteCompany: `${admin}/deleteCompany`,

  //category
  fetchallEventCatgeory: `${admin}/fetchallEventCatgeory`,
  addEventCategory: `${admin}/addEventCatgeory`,
  deleteEventCatgeory: `${admin}/deleteEventCatgeory`,

  //static
  staticContent: `${admin}/staticContent`,
  createStaticContent: `${admin}/createStaticContent`,

  // block
  activeBlockcompanyByAdmin: `${admin}/activeBlockcompanyByAdmin`,

  //advertisement
  createAdvertisement: `${admin}/createAdvertisement`,
  getAdvertisements: `${admin}/getAdvertisements`,
  deleteAdvertisement: `${admin}/deleteAdvertisement`,

  //notification
  fetchallNotifications: `${admin}/fetchallNotifications`,
  createNotification: `${admin}/createNotification`,

  //faq
  addFaq: `${admin}/addFaq`,
  getFaq: `${admin}/getFaq`,
  deleteFaq: `${admin}/deleteFaq`,

  //help and support
  getallhelpSupport: `${admin}/getallhelpSupport`,
  changeStatushelpSupport: `${admin}/changeStatushelpSupport`,

  //event
  getEventDetails: `${admin}/getEventDetails`,
  createEvent: `${admin}/createEvent`,
  cancelEvent: `${admin}/cancelEvent`,
  deleteEvent: `${admin}/deleteEvent`,
  approveRejectEventByAdmin: `${admin}/approveRejectEventByAdmin`,
};

export default Apiconfigs;
